import React, { Component } from 'react';
import {
    faPenSquare,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectSearch from 'react-select-search';

import { BANDWIDTH_SETTINGS_STATES } from '../../util/constants';
import ConfirmActionModal from '../modals/ConfirmActionModal';
import List from "../List";
import { withLoadingAndError } from "../higher-order/ComponentWithLoadingAndError";

const ListwithLoading = withLoadingAndError(List);

const registrationTableHeaders = [
    {
        header: 'Status',
        accessor: 'status',
    },
    {
        header: 'Registration',
        accessor: 'registration',
    },
    {
        header: 'Campaign',
        accessor: 'campaign',
    },
    {
        header: 'Subaccount',
        accessor: 'subaccount',
    },
    {
        header: 'Location',
        accessor: 'location',
    },
    {
        header: 'Tools',
        accessor: 'tools',
    },
];

const {
    REGISTRATION_PENDING,
    REGISTRATION_READY,
    ACCOUNTS_CREATED,
    NUMBER_PURCHASE_PENDING,
    NUMBER_PURCHASE_COMPLETE,
    NUMBER_REG_PENDING,
    NUMBER_REG_COMPLETE,
    REGISTRATION_AVAILABLE,
    REGISTRATION_AVAILABLE_PARTIAL,
    REGISTRATION_CAMPAIGN_UNLINKED,
    REGISTRATION_CAMPAIGN_ACTIVE,
    NUMBER_UNREG_PENDING,
    NUMBER_UNREG_COMPLETE,
    NUMBER_RELEASE_PENDING,
    NUMBER_RELEASE_COMPLETE,
    REG_REMOVE_PENDING,
} = BANDWIDTH_SETTINGS_STATES;

function getDisabledStatesForButtons(bwSettings) {
    const buttonDisabledStates = {
        addLines: true,
        releaseLines: true,
        campaignAssignment: true,
        editRegistration: true,
        deleteRegistration: true,
    };
    switch (bwSettings.status) {
        case ACCOUNTS_CREATED:
        case REG_REMOVE_PENDING:
        case NUMBER_UNREG_PENDING:
        case NUMBER_PURCHASE_PENDING:
        case REGISTRATION_PENDING:
        case NUMBER_RELEASE_PENDING:
        case NUMBER_REG_PENDING:
        case NUMBER_RELEASE_COMPLETE:
        case NUMBER_PURCHASE_COMPLETE:
        case REGISTRATION_AVAILABLE_PARTIAL:
            break;

        case REGISTRATION_READY:
            buttonDisabledStates.addLines = false;
            buttonDisabledStates.deleteRegistration = false;
            buttonDisabledStates.editRegistration = false;
            buttonDisabledStates.campaignAssignment = true;
            break;


        case NUMBER_UNREG_COMPLETE:
        case REGISTRATION_CAMPAIGN_UNLINKED:
            buttonDisabledStates.addLines = true;
            buttonDisabledStates.deleteRegistration = true;
            buttonDisabledStates.campaignAssignment = false;
            buttonDisabledStates.editRegistration = true;

            break;
        case NUMBER_REG_COMPLETE:
        case REGISTRATION_AVAILABLE:
            buttonDisabledStates.releaseLines = false;
            buttonDisabledStates.campaignAssignment = false;
            buttonDisabledStates.editRegistration = false;
            break;
        case REGISTRATION_CAMPAIGN_ACTIVE:
            buttonDisabledStates.releaseLines = true;
            buttonDisabledStates.deleteRegistration = true;
            buttonDisabledStates.campaignAssignment = false;
            buttonDisabledStates.editRegistration = false;
            break;

        default:
    }
    return buttonDisabledStates;
}

export default class RegistrationsList extends Component {
    
    generateRegistrationCell = (registrationId, bwSettingsId, buttonStatuses) => {
        return (
            <span className='registration-cell is-flex is-centered is-align-items-center is-justify-content-space-between'>
                <p>{`${registrationId} (${bwSettingsId})`}</p>
                <div className='buttons is-right'>
                    <button disabled={buttonStatuses.editRegistration} className='button normal with-icon' onClick={() => this.props.openEditRegistrationModal(bwSettingsId)} title='Edit Registration'>
                        <span className='icon is-medium'>
                            <FontAwesomeIcon icon={faPenSquare} />
                        </span>
                    </button>
                    <ConfirmActionModal
                        disabled={buttonStatuses.deleteRegistration}
                        title='Delete this registration'
                        actionName='delete registration'
                        itemName={registrationId}
                        actionCallback={() => this.props.deleteRegistration(bwSettingsId)}
                        buttonClass='button with-icon danger rounded delete-registration'
                        visibleButtonOverride={
                            <span className='icon is-medium'>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                        }
                    />
                </div>
            </span>
        );
    }

    generateCampaignCell = (registration, campaignDropdownSearchOptions, buttonStatuses) => {
        // Commenting out the section to enable mutli select
        return (
            <div className='campaign-cell is-flex justify-items-space-between is-align-items-center'>
                <SelectSearch
                    disabled={buttonStatuses.campaignAssignment}
                    options={campaignDropdownSearchOptions} value={registration.campaignid}
                    placeholder='Select a Campaign' name='campaign' search={true}
                    // multiple={true}

                    onChange={(selectedCampaignId) => {
                        this.props.onCampaignAssignmentChange(registration.id, selectedCampaignId)
                        return selectedCampaignId;
                    }}

                // renderOption={(props, optionData, optionSnapshot, className) => {
                //     const icon = className.includes('select-search-is-selected') ? faCheckSquare : faSquare;
                //     return <button className={className} {...props} >
                //         <span className=''>
                //             <FontAwesomeIcon icon={icon} />
                //             {optionData.name}
                //         </span>
                //     </button>
                // }}
                />
                <button disabled={buttonStatuses.campaignAssignment || !registration.campaignid} onClick={() => this.props.unassignAllCampaigns(registration.id, registration.campaignid)} className='button with-icon danger rounded'>
                    <span className='icon'>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </span>
                </button>
            </div>
        );
    }

    // This function should generate the buttons with their respecive callbacks for each row
    generateToolButtons = (bwSettings, buttonStatuses) => {

        const toolButtons = [];
                
        if (bwSettings) switch (bwSettings.status) {
            // The missing `break` statements are intentional
            // case ACCOUNTS_CREATED:
            //     break;
            // case REG_REMOVE_PENDING:
            //     break;
            // case NUMBER_UNREG_PENDING:
            //     break;
            // case NUMBER_PURCHASE_PENDING:
            //     break;
            // case REGISTRATION_PENDING:
            //     break;
            // case NUMBER_RELEASE_PENDING:
            //     break;
            // case NUMBER_RELEASE_COMPLETE:
            //     break;
            case REGISTRATION_READY:
                toolButtons.push(
                    <button disabled={buttonStatuses.addLines} className='button is-link' onClick={() => this.props.linkNewLines(bwSettings.id)} key='tool-add-lines'>
                        + Add Phones
                    </button>
                );
                break;

            // case NUMBER_PURCHASE_COMPLETE:
            //     break;
            // case NUMBER_REG_PENDING:
            //     break;
            case REGISTRATION_AVAILABLE_PARTIAL:
                // TODO: there should be a button for releasing unregistered lines
                break;
            // case NUMBER_UNREG_COMPLETE:
            //     break;    
            // case NUMBER_REG_COMPLETE:
            //     break;
            // Unlinked just means that the lines aren't linked
            // mostly happens when there's no sample yet.
            // case REGISTRATION_CAMPAIGN_UNLINKED:
            case REGISTRATION_CAMPAIGN_ACTIVE:
                break;
            // NOTE: Only allow line release when the registration has no campaign assigned
            case REGISTRATION_AVAILABLE:
                toolButtons.push(
                    <ConfirmActionModal
                        key='tool-release-lines'
                        title="Release this registrations's lines"
                        actionName='Release Lines'
                        itemName={bwSettings.registration_id}
                        buttonClass='button is-primary'
                        message={<>Are you sure you want to release all lines for registration <b>{bwSettings.registration_id}</b>?</>}
                        actionCallback={() => this.props.releaseLines(bwSettings.id)}
                        disabled={buttonStatuses.releaseLines}
                    />
                )
            break;
        
            default:
                toolButtons.push(
                    <button className='button is-link' onClick={() => this.props.linkNewLines(bwSettings.id)} disabled key='tool-add-lines'>
                        + Add Phones
                    </button>
                )
            break;
        }

        return (
            <span className='buttons'>
                {toolButtons}
            </span>
        );
    }

    render() {
        const { campaigns, registrations, loading, } = this.props;
        const campaignMap = new Map();
        // I don't like to re-map these values every render

        /*
        This is pretty ugly, but we can use this iteration to set both the campagin map and the campaign dropdown options
        
        `campaignDropdownSearchOptions` -- used by <SelectSearch>.
        Uses the `value` key of the option when it triggers the `onChange` callback
        Uses the `name` key of the option as the basis of the search, which is why we should overwrite `name` from the original campaign: so users and search for the ID of the campaign instead of only the name  
        
        `campaignMap` is used to lookup campaign data in O(1) when iterating through registrations
        */

        const campaignDropdownSearchOptions = [
            {
                type: 'group',
                name: 'Unassigned Campaigns',
                items: []
            },
            {
                type: 'group',
                name: 'Assigned Campaigns',
                items: [],
            }
        ];

        const allCampaignsWithAssignedRegistration = [];
        for (const reg of registrations.values()) {
            if (reg.campaignid) allCampaignsWithAssignedRegistration.push(reg.campaignid);
        }
        
        // Group campaigns by whether or not they have been assigned to a registration
        
        const items = { assigned: [], unassigned: [] };
        for (const camp of campaigns) {
            campaignMap.set(camp.campaignid, camp);
            if (allCampaignsWithAssignedRegistration.includes(camp.campaignid)) {
                items.assigned.push(camp);
            }
            else {
                items.unassigned.push(camp);
            }
        }

        campaignDropdownSearchOptions[0].items = items.unassigned.map((camp) => ({ name: `${camp.campaignid} - ${camp.name}`, value: camp.campaignid }));
        campaignDropdownSearchOptions[1].items = items.assigned.map((camp) => ({ disabled: true, name: `${camp.campaignid} - ${camp.name}`, value: camp.campaignid }));
        
        const registrationsToRender = []; // If we get many registration, it might be worth it to preallocate the array
        if (registrations) {
            const regArray = [];
            for (const [bwSettingsId, registration] of registrations) {
                regArray.push(registration);
            }
            regArray.sort((a, b) => {
                if (a.status > b.status) {
                    return 1
                }
                if (a.status < b.status) {
                    return -1
                }
                if (a.id > b.id) {
                    return 1
                }
                if (a.id < b.id) {
                    return -1
                }
                return 0;
            });

            for (const registration of regArray) {
                const buttonStatuses = getDisabledStatesForButtons(registration);
                const status = registration.status;
                registrationsToRender.push({
                    status: <p>{registration.status}</p>,
                    registration: this.generateRegistrationCell(registration.registration_id, registration.id, buttonStatuses),
                    subaccount: <p>{ registration.subaccount_id || '-'}</p>,
                    location: <p>{ registration.location_id || '-'}</p>,
                    campaign: this.generateCampaignCell(registration, campaignDropdownSearchOptions, buttonStatuses),
                    tools: this.generateToolButtons(registration, buttonStatuses)
                });
            }

        }
        return (
            <ListwithLoading
                headers={registrationTableHeaders}
                list={registrationsToRender}
                loading={loading}
                id="registrations-list"
                className='registration-list'
            />
        )
    }
}
