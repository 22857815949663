import { combineReducers } from "redux";
import { createAddErrorMsgReducer, loadStateReducer } from "../../util/reusableReducers";
import reduceReducers from "reduce-reducers";
import {
  GET_BANDWIDTH_REGISTRATION,
  GET_ALL_BANDWIDTH_REGISTRATIONS,
  BANDWIDTH_BRANDS,
  REGISTER_OUTGOING_LINES,
  REGISTER_CAMPAIGN,
  GET_CAMPAIGN_REGISTRATION,
  UPDATE_BANDWIDTH_REGISTRATION,
  CREATE_BANDWIDTH_REGISTRATION,
  ASSIGN_BANDWIDTH_REGISTRATION_TO_CAMPAIGN,
  CREATE_NEW_BANDWIDTH_REGISTRATION,
  GET_BW_REGISTRTIONS_ASSIGNED_TO_CAMPAIGN,
  DELETE_BANDWIDTH_REGISTRATION,
  PURCHASE_BANDWIDTH_LINES,
  RELEASE_BANDWIDTH_LINES,
  UNASSIGN_BANDWIDTH_REGISTRATION_FROM_CAMPAIGN,
  LINK_REGISTRATION,
} from './actions';
import { FETCH_ADD_ERROR_MSG, FETCH_CLEAR_ERROR_MESSAGE } from "../FetchResponseManager/actions";

const bwActions = [
  [ UNASSIGN_BANDWIDTH_REGISTRATION_FROM_CAMPAIGN, 'DELETE' ],
  [ RELEASE_BANDWIDTH_LINES, 'DELETE' ],
  [ PURCHASE_BANDWIDTH_LINES, 'PUT' ],
  [ DELETE_BANDWIDTH_REGISTRATION, 'DELETE' ],
  [ GET_BW_REGISTRTIONS_ASSIGNED_TO_CAMPAIGN, 'GET' ],
  [ CREATE_NEW_BANDWIDTH_REGISTRATION, 'PUT' ],
  [ ASSIGN_BANDWIDTH_REGISTRATION_TO_CAMPAIGN, 'PUT' ],
  [ CREATE_BANDWIDTH_REGISTRATION, 'POST' ],
  [ UPDATE_BANDWIDTH_REGISTRATION, 'PATCH' ],
  [ GET_BANDWIDTH_REGISTRATION, 'GET' ],
  [ GET_ALL_BANDWIDTH_REGISTRATIONS, 'GET' ],
  [ BANDWIDTH_BRANDS, 'POST' ],
  [ REGISTER_OUTGOING_LINES, 'POST' ],
  [ REGISTER_CAMPAIGN, 'POST' ],
  [ GET_CAMPAIGN_REGISTRATION, 'POST' ],
  [ LINK_REGISTRATION, "POST"]
];

/**
 * 
 * @param {string} actionName The name of the base action
 * @param {string[]} method method used for fetch calls
 * @returns {Object} This will include loading and error states, as well as success states for the given methods 
 */
function generateLoadStateReducerParams(actionName, method) {
  return Object.fromEntries([
    [ actionName + "_IS_LOADING",  "loading"],
    [ actionName + "_HAS_ERROR",  "error"],
    [`${actionName}_${method.toUpperCase()}_SUCCESS`, 'success' ],
  ]);
}

const finalReducers = new Map();

for (const [action, method] of bwActions) {
  const baseReducer = loadStateReducer(generateLoadStateReducerParams(action, method));
  const errorMessageReducer = createAddErrorMsgReducer(baseReducer);
  finalReducers.set(action, reduceReducers(baseReducer, errorMessageReducer))
}

function errorReducer (state = {}, action){
  const actionName = bwActions.find( act => act[0] === action.requestName);
  if (!actionName) {
    return state;
  }

  if (action.type === FETCH_CLEAR_ERROR_MESSAGE) {
    if (state.error && state.error.requestName === action.requestName)
      // FIXME: This is a hack to get the error to clear. Remove the next line before committing
      console.log("WHY WON'T THIS WORK WITHOUT THIS LINE????????REHNIO'NR");
      return {
        error: null
      };
  }
  else if (action.type.endsWith('_HAS_ERROR')) {
    let message = action.message;
    switch (action.requestName) {
      case UNASSIGN_BANDWIDTH_REGISTRATION_FROM_CAMPAIGN:
        message = 'Failed to unassign bandwidth registration from campaign';
        break;
      case RELEASE_BANDWIDTH_LINES:
      case PURCHASE_BANDWIDTH_LINES:
      case DELETE_BANDWIDTH_REGISTRATION:
      case GET_BW_REGISTRTIONS_ASSIGNED_TO_CAMPAIGN:
      case CREATE_NEW_BANDWIDTH_REGISTRATION:
      case ASSIGN_BANDWIDTH_REGISTRATION_TO_CAMPAIGN:
      case CREATE_BANDWIDTH_REGISTRATION:
      case UPDATE_BANDWIDTH_REGISTRATION:
        message = 'Failed to update bandwidth registration';
        break;
      case GET_BANDWIDTH_REGISTRATION:
        message = "Failed to fetch registration data. Try again after a short wait."
        break;
      case GET_ALL_BANDWIDTH_REGISTRATIONS:
      case BANDWIDTH_BRANDS:
      case REGISTER_OUTGOING_LINES:
      case REGISTER_CAMPAIGN:
      case GET_CAMPAIGN_REGISTRATION:
      case LINK_REGISTRATION:
        message = "Failed to link existing bandwidth registration."
        break;
    }
    return {
      requestName: action.requestName,
      message,
    };
  }
  
  return state;
}

export default combineReducers({
  brands: finalReducers.get(BANDWIDTH_BRANDS),
  registerLines: finalReducers.get(REGISTER_OUTGOING_LINES),
  registerCampaign: finalReducers.get(REGISTER_CAMPAIGN),
  getRegistration: finalReducers.get(GET_CAMPAIGN_REGISTRATION),
  getAllBandwidthRegistrations: finalReducers.get(GET_ALL_BANDWIDTH_REGISTRATIONS),
  getBandwidthRegistrationById: finalReducers.get(GET_BANDWIDTH_REGISTRATION),
  getBandwidthRegistrationsAssignedToCampaign: finalReducers.get(GET_BW_REGISTRTIONS_ASSIGNED_TO_CAMPAIGN),
  createNewBandwidthRegistration: finalReducers.get(CREATE_NEW_BANDWIDTH_REGISTRATION),
  assignBandwidthRegistrationToCampaign: finalReducers.get(ASSIGN_BANDWIDTH_REGISTRATION_TO_CAMPAIGN),
  createBandwidthRegistration: finalReducers.get(CREATE_BANDWIDTH_REGISTRATION),
  updateBandwidthRegistration: finalReducers.get(UPDATE_BANDWIDTH_REGISTRATION),
  getBandwidthRegistrationsAssignedToCampaign: finalReducers.get(GET_BW_REGISTRTIONS_ASSIGNED_TO_CAMPAIGN),
  deleteBandwidthRegistration: finalReducers.get(DELETE_BANDWIDTH_REGISTRATION),
  purchaseBandwidthLines: finalReducers.get(PURCHASE_BANDWIDTH_LINES),
  releaseBandwidthLinesFromSettings: finalReducers.get(RELEASE_BANDWIDTH_LINES),
  linkExistingRegistration: finalReducers.get(LINK_REGISTRATION),
  error: errorReducer,
});