import React, { Component } from "react";
import SelectSearch from 'react-select-search';
import { S160Field } from "../common/S160Field/S160Field";
import { LoadingSpinner } from "../LoadingSpinner";

class BandwidthTools extends Component {

  constructor(props) {
    super(props);

    this.state = {
      prevCampaignid: null,
      assignedLoaded: false,
      availableLoaded: false,
      assigned: {},
      available: [],
      refresh: false
    }
  }

  componentDidMount() {
    // NOTE: I've renamed the request states to avoid conflicts
    // getRegState: getAllRegistrations
    // assignCampaignState: assignBandwidthRegistrationToCampaign
    this.getAssigned();
    this.getAvailable();
  }

  getAssigned = async () => {
    this.setState({ prevCampaignid: this.props.campaignid, refresh: false });
    await this.props.getAssignedRegistration();
    this.setState({
      prevCampaignid: this.props.campaignid,
      assignedLoaded: true,
      assigned: this.props.getRegState.data.find(setting => setting.campaignid == this.props.campaignid) || {},
      refresh: false,
    });
  }

  getAvailable = async () => {
    await this.props.getAvailableRegistrations();
    this.setState({
      availableLoaded: true,
      available: this.props.getRegState.data,
      refresh: false,
    });
  }

  componentDidUpdate() {
    const {campaignid, getRegState, assignCampaignState } = this.props;
    const { loading, error, data: bwSettings }  = getRegState;
    
    // Can't do anything while loading 
    if (loading) {
      return;
    }
    
    // On campaign change, reload selected data
    if (this.props.campaignid !== this.state.prevCampaignid || this.state.refresh) {
      this.getAssigned();
      return;
    }
  }

  onDropdownChange = async (selectedBwSettingsId) => {
    await this.props.assignBandwidthRegistrationToCampaign(selectedBwSettingsId, this.props.campaignid, [false, this.props.campaignid]);
    this.setState({ assigned: this.props.getRegState.data.find(setting => setting.campaignid == this.props.campaignid) || {} });
  }

  render() {
    const {
      has_unassigned_recipients,
      pnpercopilot,
      campaignid,
      getRegState,
    } = this.props;

    if (getRegState.loading) {
      return <LoadingSpinner />;
    } 

    const { assigned: selectedBwSettings, available } = this.state;

    let dropdownValue;

    if ( selectedBwSettings ) {
      dropdownValue = selectedBwSettings.id;
    } else {
      dropdownValue = null;
    }

    let dropdownList = available;
    if (selectedBwSettings.id && available) {
      dropdownList = [...available, selectedBwSettings];
    }

    return (
      <div className="tile is-parent is-vertical">
        <h4 className="title is-4 has-text-centered">BANDWIDTH SETTINGS</h4>
        <div className="tile is-parent">
          <div className="tile is-child">
            <S160Field
              name="Subaccount ID"
              value={selectedBwSettings.subaccount_id}
              fallbackValue="-- Subaccount ID not found --"
            />
            <S160Field
              name="Location ID"
              value={selectedBwSettings.location_id}
              fallbackValue="-- Location ID not found --"
            />
            <S160Field
              name="Registration ID"
              value={selectedBwSettings.registration_id}
              fallbackValue="-- Registration ID not found --"
            />
            <S160Field
              name="Are there unassigned recipients?"
              value={has_unassigned_recipients ? "Yes" : "No"}
              fallbackValue="-- Unassigned recipients not found --"
            />
          </div>
          <div className="tile is-child is-right">
            <div className="buttons is-right">
              {/* TODO: Lock this when selected. Make the user deselect it before new selection */}
              <SelectSearch
                options={dropdownList.map(setting => ({ value: setting.id, name: `${setting.id} - ${setting.registration_id}` }))}
                value={dropdownValue}
                search
                emptyMessage="No registrations found"
                placeholder="Choose a registration"
                onChange={this.onDropdownChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BandwidthTools;
