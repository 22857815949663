import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import {
  VERTICAL_DROPDOWN_FIELD_OPTIONS,
  STANDARD_CAMPAIGN_TYPES,
  SPECIAL_CAMPAIGN_TYPES,
  // SAMPLE_CHAR_LIMIT_RE_PATTERN,
  CAMPAIGN_REGISTRATION_OPTIONS_TEXT,
  CAMPAIGN_REGISTRATION_OPTIONS_REQUIRED_TEXT,
  DEFAULT_VALUES_FOR_NEW_FIELDS,
  INIT_VALUES,
  VALIDATION_SCHEMA,
} from "./constants";
import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";

const BandwidthRegistrationFormV2 = ({
  disabled = true,
  brands = [],
  registrationData,
  children,
  submitRegistration,
}) => {
  function onSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    submitRegistration(values);
  };

  const registrationId = registrationData ? registrationData.registration_id : null;

  const initValues = {
    ...INIT_VALUES,
    ...DEFAULT_VALUES_FOR_NEW_FIELDS,
    ...registrationData
  };
  // default to the first brand if no registration data
  initValues.brandId = registrationData ? registrationData.brandId : brands[0].brand_id;
  const checkboxTexts = {
    ...CAMPAIGN_REGISTRATION_OPTIONS_REQUIRED_TEXT,
    ...CAMPAIGN_REGISTRATION_OPTIONS_TEXT,
  };

  const disableNonEditableFields = disabled || registrationData;

  return (
    <div>
      {registrationId && (
        <div className="field">
          <label className="label">Registration ID</label>
          {registrationId}
        </div>
      )}
      <Formik
        initialValues={initValues}
        enableReinitialize={true}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="form">
            {children}
            {/* {console.log(isValid, errors, values)} */}
            <div className="field">
              <label htmlFor="brandId" className="label">
                Brand ID
              </label>
              <div className="select">
                <Field as="select" name="brandId" disabled={disableNonEditableFields} value={initValues.brandId}>
                  {brands.map((item, ind) => (
                    <option key={`type-brands-${ind}`} value={item.brand_id} >
                      {item.brand_name} - ( {item.brand_id} )
                    </option>
                  ))}
                </Field>
              </div>
              <ErrorMessage
                name="brandId"
                component="div"
                className="help is-danger"
              />
            </div>
            <div className="field is-grouped">
              <div className="control">
                <label htmlFor="usecase" className="label">
                  Campaign Type
                </label>
                <div className="select">
                  <Field as="select" name="usecase" disabled={disableNonEditableFields}>
                    {STANDARD_CAMPAIGN_TYPES.map((item, ind) => (
                      <option
                        key={"type-" + item.field + "-" + ind}
                        value={item.field}
                      >
                        {item.display}
                      </option>
                    ))}
                  </Field>
                </div>
                <ErrorMessage
                  name="usecase"
                  component="div"
                  className="help is-danger"
                />
              </div>
              <div className="control">
                <label htmlFor="subUsecases" className="label">
                  Special Campaign Type
                </label>
                <div className="select">
                  <Field as="select" name="subUsecases" disabled={disableNonEditableFields}>
                    {["", ...SPECIAL_CAMPAIGN_TYPES].map((item, ind) => (
                      <option
                        key={"spec-" + item.field + "-" + ind}
                        value={item.field}
                      >
                        {item.display}
                      </option>
                    ))}
                  </Field>
                </div>
                <ErrorMessage
                  name="subUsecases"
                  component="div"
                  className="help is-danger"
                />
              </div>
              <div className="control">
                <label htmlFor="vertical" className="label">
                  Vertical
                </label>
                <div className="select">
                  <Field as="select" name="vertical" disabled={disableNonEditableFields}>
                    {["", ...VERTICAL_DROPDOWN_FIELD_OPTIONS].map(
                      (item, ind) => (
                        <option
                          key={"vertical-" + item.field + "-" + ind}
                          value={item.field}
                        >
                          {item.display}
                        </option>
                      )
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name="vertical"
                  component="div"
                  className="help is-danger"
                />
              </div>
            </div>

            <div>
              <label htmlFor="description" className="label">
                Description
              </label>
              <Field
                type="text"
                name="description"
                className="input"
                disabled={disableNonEditableFields}
              />
              <ErrorMessage
                name="description"
                component="div"
                className="help is-danger"
              />
            </div>

            <hr />
            <h1 className="title is-4">Sample Messages</h1>
            <div>
              <label htmlFor="sample1" className="label">
                Sample 1
              </label>
              <Field
                type="text"
                name="sample1"
                className="input"
                disabled={disabled}
              />
              <ErrorMessage
                name="sample1"
                component="div"
                className="help is-danger"
              />
            </div>
            <div>
              <label htmlFor="sample2" className="label">
                Sample 2
              </label>
              <Field
                type="text"
                name="sample2"
                className="input"
                disabled={disabled}
              />
              <ErrorMessage
                name="sample2"
                component="div"
                className="help is-danger"
              />
            </div>
            <div>
              <label htmlFor="sample3" className="label">
                Sample 3
              </label>
              <Field
                type="text"
                name="sample3"
                className="input"
                disabled={disabled}
              />
              <ErrorMessage
                name="sample3"
                component="div"
                className="help is-danger"
              />
            </div>
            <div>
              <label htmlFor="sample4" className="label">
                Sample 4
              </label>
              <Field
                type="text"
                name="sample4"
                className="input"
                disabled={disabled}
              />
              <ErrorMessage
                name="sample4"
                component="div"
                className="help is-danger"
              />
            </div>
            <div>
              <label htmlFor="sample5" className="label">
                Sample 5
              </label>
              <Field
                type="text"
                name="sample5"
                className="input"
                disabled={disabled}
              />
              <ErrorMessage
                name="sample5"
                component="div"
                className="help is-danger"
              />
            </div>
            <div>
              <label htmlFor="helpMessage" className="label">
                Help message
              </label>
              <Field
                type="text"
                name="helpMessage"
                className="input"
                disabled={disableNonEditableFields}
              />
              <ErrorMessage
                name="helpMessage"
                component="div"
                className="help is-danger"
              />
            </div>
            <div>
              <label htmlFor="messageFlow" className="label">
                Message flow description
              </label>
              <Field
                type="text"
                name="messageFlow"
                className="input"
                disabled={disableNonEditableFields}
              />
              <ErrorMessage
                name="messageFlow"
                component="div"
                className="help is-danger"
              />
            </div>
            <div>
              <label htmlFor="optoutMessage" className="label">
                Opt-out message
              </label>
              <Field
                type="text"
                name="optoutMessage"
                className="input"
                disabled={disableNonEditableFields}
              />
              <ErrorMessage
                name="optoutMessage"
                component="div"
                className="help is-danger"
              />
            </div>
            <hr />
            <h1 className="title is-4">Options</h1>

            {Object.keys(checkboxTexts).map((k, i) => (
              <div className="control" key={`check-registration-${i}`}>
                <label className="label">
                  <Field
                    type="checkbox"
                    name={k}
                    className="checkbox"
                    disabled={disableNonEditableFields}
                  />
                  &nbsp; {checkboxTexts[k]}
                </label>
                <ErrorMessage
                  name={k}
                  component="div"
                  className="help is-danger"
                />
              </div>
            ))}

            <br />

            <BulmaButtonProper
              disabled={disabled || isSubmitting || !dirty}
              type="submit"
              customClass={isValid ? 'is-success' : 'is-warning'}
            >
              Submit
            </BulmaButtonProper>
            {children}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BandwidthRegistrationFormV2;
