// Shows and manages users via UserFilters and via ListWithLoading.
// This also contains the ConfirmDeleteUserModal which is activated and filled for a particular user
// when the user's corresponding delete is pressed.
import React, { Component } from "react";

import RegistrationsList from "./RegistrationsList";
import EditRegistrationModal from "./EditRegistrationModal";
import AddPhoneLinesModal from "./AddPhoneLinesModal";

// import { BANDWIDTH_SETTINGS_STATES } from "../util/constants";
import { ErrorNotification } from "../generic/Notifications/ErrorNotification";
import { LoadingSpinner } from "../LoadingSpinner"; 
import { LinkRegistrationModal } from "./LinkRegistrationModal";


// Putting this here instead of the state so that we don't re-render the page when we set the timeout
let timeoutId = null;

class AggregatorSettingsPage extends Component {

    
    state = {
        changed: false,
        isEditRegistrationModalOpen: false,
        editingRegistrationId: null,
        isNewRegistration: false,
        addNewLinesRegistrationId: null,
        bwSettingsId: null,
        error: {
            message: "",
        }
    };

    componentDidMount() {
        this.props.getAllBandwidthRegistrations();
    }

    clearError = (timeoutSecs = 0) => {
        if (timeoutSecs) timeoutId = setTimeout(() => {
                timeoutId = null;
                this.props.clearErrorMsg(this.props.error.requestName);
            },
            timeoutSecs * 1e3
        );
        else {
            timeoutId = null;
            this.props.clearErrorMsg(this.props.error.requestName);
        }
    }

    releaseLines = (bwSettingsId) => {
        this.props.releaseBandwidthLinesFromSettings(bwSettingsId);
    }

    deleteRegistration = (bwSettingsId) => {
        this.props.deleteBandwidthRegistration(bwSettingsId);
    }

    openEditRegistrationModal = (bwSettingsId, isNewRegistration = false) => {
        const newState = {
            isEditRegistrationModalOpen: true,
        };
        if (isNewRegistration) newState.isNewRegistration = true;
        else newState.bwSettingsId = bwSettingsId;

        this.setState(newState);
    }

    closeEditRegistrationModal = () => {
        if (this.state.isEditRegistrationModalOpen) this.setState({ isEditRegistrationModalOpen: false, isNewRegistration: false, bwSettingsId: null });
    }

    onEditRegistrationModalSubmit = (submittedValues) => {
        if (this.state.isNewRegistration) {
            this.props.createNewBandwidthRegistration(submittedValues);
        }
        else {
            this.props.updateBandwidthRegistration(this.state.bwSettingsId, submittedValues)
        }
        this.closeEditRegistrationModal();
    }

    onCampaignAssignmentChange = async (bwSettingsId, campaignId) => {
        console.log('Changing campaign assignemnt of registration:', bwSettingsId, campaignId);
        this.props.assignBandwidthRegistrationToCampaign(bwSettingsId, campaignId);
    }

    onCampaignUnassignment = async (bwSettingsId, campaignId) => {
        console.log('Unassigning campaign:', bwSettingsId, campaignId);
        this.props.unassignBandwidthRegistration(bwSettingsId, campaignId);
    }

    setBwSettingsIdForNewLines = (bwSettingsId) => {
        this.setState({ addNewLinesRegistrationId: bwSettingsId });
    }

    linkNewLines = async (bwSettingsId, numberOfNewLines) => { 
        console.log('Linking new lines:', bwSettingsId, numberOfNewLines);
        try {
            await this.props.purchaseBandwidthLines(bwSettingsId, numberOfNewLines);

        }
        catch (err) {
            console.log('Failed in linking new lines for ID:', bwSettingsId);
        }
        
     }

    closeAddLinesModal = () => {
        this.setState({ addNewLinesRegistrationId: null });
    }

    getRegistrationById = (bwSettingsId) => {
        this.props.getBandwidthRegistrationById(bwSettingsId);
    }

    render() {
        const { campaigns, registrations, error } = this.props;
        const { isEditRegistrationModalOpen, bwSettingsId } = this.state;
        if (error.message) this.clearError(8);
        
        // We only want to be able to select campaigns that are active, prelaunch, or paused
        const activeCampaigns = campaigns.filter(campaign => ["active", "prelaunch", "paused"].includes(campaign.active));

        return (
            <div id="aggregator-settings-page" className="aggregator-settings-page">
                <ErrorNotification condition={error.message} >
                    {error.message}
                </ErrorNotification>
                <div id="bandwidth-settings-top-buttons">
                    <button className="button" onClick={() => this.openEditRegistrationModal(null, true)}>
                        Add New +
                    </button>
                    <LinkRegistrationModal onSubmit={this.props.linkExistingRegistration}/>
                </div>
                <br />
                <div id="registrations-list-wrapper" >
                    <RegistrationsList
                        campaigns={activeCampaigns}
                        registrations={registrations}
                        openEditRegistrationModal={this.openEditRegistrationModal}
                        onCampaignAssignmentChange={this.onCampaignAssignmentChange}
                        deleteRegistration={this.deleteRegistration}
                        unassignAllCampaigns={this.onCampaignUnassignment}
                        releaseLines={this.releaseLines}
                        linkNewLines={this.setBwSettingsIdForNewLines}
                    />
                </div>
                <div className="edit-registrations-modal-wrapper">
                    <EditRegistrationModal
                        isOpen={isEditRegistrationModalOpen && !error.message}
                        toggleModal={this.closeEditRegistrationModal}
                        onSubmit={this.onEditRegistrationModalSubmit}
                        isNewRegistration={this.state.isNewRegistration}
                        bwSettings={registrations.get(bwSettingsId)}
                        registrationData={this.props.registrationData}
                        deleteRegistration={this.deleteRegistration}
                        getBandwidthRegistrationById={this.getRegistrationById}
                    />
                </div>
                <div className="add-lines-modal-wrapper">
                    <AddPhoneLinesModal
                        headerText="Add Phone Lines"
                        registrationId={this.state.addNewLinesRegistrationId}
                        linkNewLines={this.linkNewLines}
                        open={!!this.state.addNewLinesRegistrationId}
                        closeAddLinesModal={this.closeAddLinesModal}
                    />
                </div>
            </div>
        );
    }
}

export default AggregatorSettingsPage;
