// Simply get's the settings data for a specific campaign and passes it down
import React from "react";
import { connect } from "react-redux";

import BandwidthTools from "./BandwidthTools";
import { bindActionCreators } from "redux";

import { assignBandwidthRegistrationToCampaign, getAllBandwidthRegistrations } from "../../redux/Bandwidth/actions";

function ConnectedSettingsData ({campaignid, ...props}) {

    // Since we only want the available registrations, pass true
    const getAvailableRegistrations = () => {
      return props.getAllBandwidthRegistrations(true);
    }

    const getAssignedRegistration = () => {
      return props.getAllBandwidthRegistrations(false, campaignid);
    }

    return (
         <BandwidthTools {...props} campaignid={campaignid} getAvailableRegistrations={getAvailableRegistrations} getAssignedRegistration={getAssignedRegistration}/>
    );
  }


const mapStateToProps = state => {
  const {getAllBandwidthRegistrations, assignBandwidthRegistrationToCampaign} = state.bandwidth;
  return ({
    getRegState: getAllBandwidthRegistrations,
    assignCampaignState: assignBandwidthRegistrationToCampaign,
})};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllBandwidthRegistrations: (activeOnly, campaignId) => getAllBandwidthRegistrations(activeOnly, campaignId),
      assignBandwidthRegistrationToCampaign,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSettingsData);
